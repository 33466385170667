<template>
  <div class="footer">
    <div class="info">
      <div class="fl">
        <p>公司名称：海南趣萌网络科技有限公司 </p>
        <p>联系电话：15901185988</p>
        <p>联系地址：海南省海口市美兰区海府街道海府路2号海口宾馆二楼213房26号</p>
        <p>网站备案：<a target="_blank" href="https://beian.miit.gov.cn" >琼ICP备2024046997号</a></p>
        <p>© 海南趣萌网络科技有限公司 版权所有 http://www.qumengtech.com</p>
      </div>
    </div>
    <!-- <div style="width:300px;margin:0 auto; padding:20px 0;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010502000465" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="/static/g.png" style="float:left;"><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">琼公网安备 46010502000465号</p></a>
    </div> -->
  </div>
</template>

<script>
import { linkArr } from '@/config/json.js'
import { _getAbout } from '@/config/api.js'
export default {
  name: 'footer',
  data () {
    return {
      isYue: true, // true广州，false海南
      showStatic: false, // 弹窗图片
      showNum: 1, // 弹窗图片路径
      popStatic: true,
      imgStatic: false,
      status: false,
      links: '',
      aboutData: {},
      post: {
        type: 1,
        mobile: '',
        name: '',
        address: ''
      }
    }
  },
  created () {
    this.links = linkArr
    // document.addEventListener('scroll', this.scroll, false)
  },
  mounted () {
    this.getAbout()
  },
  methods: {
    getAbout () {
      _getAbout().then((res) => {
        this.aboutData = res.data
      })
    },
    // 回到顶部
    goTop () {
      let scrollToTop = window.setInterval(() => {
        let Top = document.documentElement.scrollTop || document.body.scrollTop
        if (Top > 0) {
          window.scrollTo(0, Top - 80)
        } else {
          window.clearInterval(scrollToTop)
        }
      }, 10)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/scss" lang="scss" scoped>
.footer {
  width: 100%;
  margin-top: 20px;
  background: #13212a;border-top: #ddd solid 1px;
  .info{
    width: 1000px;margin: auto;overflow: hidden;padding: 30px 0 30px 0;
    .fr{float: right;
      .item{
        float: left;margin-right: 10px;
        img{width: 110px;height: 110px;}
        .p2{text-align: center;color: #fff;font-size: 14px;line-height: 2.4;}
      }
    }
    .fl{float: left;padding-left: 10px;padding-top: 4px;
      p{font-size: 16px;color: #fff;line-height: 2.2;}
      a{font-size: 16px;color: #fff;line-height: 2.2;
        &:hover{color: #c81623;}
        }
    }
  }
}
.kefu{position: fixed;left: 50%;margin-left: 530px;width: 58px;background: #fff;bottom: 70px;z-index: 10;border: #dcdcdc solid 1px;
  .item{width: 58px;height: 58px;text-align: center;position: relative;cursor: pointer;transition: all .1s;
    &::before{
      width: 30px;height: 1px;background: #f2f2f2;content: '';position: absolute;display: block;left: 50%;margin-left: -15px;bottom: 0;
    }
    &:hover{
      background: #c81623;
      &::before{display: none;}
      span{color: #fff;}
      .images{
        display: block;
      }
      .back{display: none;}
      .icon{display: block;}
    }
    span{color: #666;width: 30px;height: 48px;padding: 0 14px;;display: inline-block;padding-top: 10px;font-size: 14px;
      &.icon{background: url('/static/back.png') center no-repeat;display: none;background-size: 50%;}
    }
    .images{
      position: absolute;padding: 5px;background: #c81623;right:58px;z-index: 10;display: none;
      img{width: 120px;}
    }
  }
}
</style>

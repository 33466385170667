<template>
  <div class="header">
    <div class="headTop">
        <div class="info">
            电话：15901185988
        </div>
    </div>
    <div class="headInfo">
        <div class="logo">
            <router-link tag="a" to="index"><img width="290" src="@/assets/logo1.png" alt=""></router-link>
        </div>
        <div class="nav">
            <ul>
                <li><router-link tag="a" to="index">首页</router-link></li>
                <li><a @click="goPage(63,'手笔文具供应')">手笔文具供应</a></li>
                <li><a @click="goPage(62,'书写文具供应')">书写文具供应</a></li>
                <li><a  @click="goCartA">发布信息</a></li>
                <!-- <li><a @click="goCart(2)">购物车</a></li> -->
                <el-button class="headBtn" v-if="!$store.state.loginStatus" @click="goLogin" type="primary">登录/注册</el-button>
                <template v-else>
                  <el-button class="headBtn" @click="goCart(1)" type="primary">个人中心</el-button>
                </template>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header',
  data () {
    return {
      bannerArr: [
        {url: '/static/t1.jpg'},
        {url: '/static/t2.jpg'},
        {url: '/static/t3.jpg'}
      ],
      loginStatus: false // 是否已登录
    }
  },
  mounted () {
    if (this.Check()) {
      let id = localStorage.getItem('userId') || ''
      let name = localStorage.getItem('userName') || ''
      this.$store.dispatch('userSync', {id: id, name: name})
    }
  },
  filters: {
    sub20 (value) {
      return value.substring(0, 20) + '...'
    }
  },
  methods: {
    goLogin(){
        this.$router.push({path: 'login'})
    },
    goCartA(){
        if(!this.Check()){
            this.$message({
            message: '请登录后再进行信息发布！',
            type: 'error'
            })
            return
        }
        this.$router.push({path: 'cart',query: {type: 2}})
    },
    goCart(value) {
        if (!this.Check()) {
          this.$message({
            message: '请登录后再进行操作！',
            type: 'error'
          })
          return
        }
        this.$router.push({path: 'cart',query: {type: value}})
    },
    // 去列表页
    goPage (val, name) {
      this.$router.push({path: 'page', query: {type: val, name: name}})
    },
    // 供销合作申请
    goHe(){
      this.$router.push({path: 'hezuo'})
    },
    clickOut () {
      localStorage.removeItem('login')
      localStorage.removeItem('loginTime')
      localStorage.removeItem('userId')
      localStorage.removeItem('userName')
      location.reload()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/scss" lang="scss" scoped>

.header {
  background: #fff;
  text-align: right;
  .top{overflow: hidden;background: #f8f8f8;border-bottom: #ebebeb solid 1px;
    .info{
      width: 1000px;margin: auto;
      span{display: inline-block;padding: 4px 10px;margin: 0 2px;cursor: pointer;color: #999;font-size: 14px;
        &:hover{color: #e4393c;}
      }
    }
  }
  .w1000{
    width: 1000px;margin: auto;overflow: hidden;
    .logo{
      overflow: hidden;float: left;
      img{
        float: left;margin: 10px 0;
      }
    }
    .cart{width: 130px;height: 34px;border: #eee solid 1px;line-height: 34px;font-size: 14px;color: #e1251b;cursor: pointer;text-align: left;float: right;margin-top: 30px;
      &:hover{border-color: #e4393c;}
      span{width: 34px;height: 34px;position: relative;float: left;background: url('/static/cart.png') center no-repeat;background-size: 60% 60%;margin-left: 10px;margin-right: 6px;
        i{position: absolute;background: #e4393c;display: block;width: 18px;height: 18px;border-radius: 50%;text-align: center;line-height: 18px;color: #fff;font-size: 12px;overflow: hidden;margin-left: 20px;}
      }
    }
  }
  .banner{
    height: 400px;width: 1000px;margin: auto;
    img{width: 100%;height: 400px;}
  }
}

.headTop{width: 100%;line-height: 56px;background: #33353b;
    .info{width: 1140px;margin: auto;color: #fff;font-size: 14px;text-align: right;}
}
.headInfo{width: 1140px;margin: auto;height: 66px;padding: 25px 0;
    .logo{float: left;
        img{width: 160px;height: 60px;}
    }
    .nav{float: right;
        ul{text-align: left;
            li{
                float: left;margin-left: 40px;line-height: 66px;
                a{
                    display: block;
                    color: #030c26;
                    font-weight: 700;
                    position: relative;
                    font-size: 16px;cursor: pointer;
                    font-family: 'Jost', sans-serif;
                    &:hover{color: #01a5c0;}
                }
            }
        }
    }
}
.headBtn{
    vertical-align: middle;
    margin-top: 12px;
    margin-left: 34px;
}
</style>
